@import 'mixins';

.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.border-0 {
  border: none;
}

.pr-10 {
  padding-right: 10px;
}

.pr-30 {
  padding-right: 30px;
}

.d-none {
  display: none;
}

.flex-wrap {
  flex-wrap: wrap;
}

.fs-18 {
  font-size: 18px;
}

.fw-400 {
  font-weight: 400;
}

.mr-10 {
  margin-right: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-13 {
  margin-top: 13px;
}

.flex-column {
  flex-direction: column;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.text-violet {
  color: rgb(111 28 111);
}

.invalid-input {
  border: 1px solid $invalid-input;
  border-radius: 29px;
  height: 60px;
}

.wrapper {
  padding: 0 30px;
}

.p-pt-5 {
  padding: 0 0.5rem;
}

.p-x-1 {
  padding: 0 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

.text-left {
  text-align: left;
}
