@use 'sass:string';
@use 'sass:map';
@use 'sass:meta';

@import 'variables';
@import 'fonts';

@mixin flex-container ($align: center, $justify: center, $flex-direction: row) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: $flex-direction;
}

@mixin box-shadow ($color: #bbbbbb) {
  box-shadow: 0 3px 5px $color;
}

@mixin font ($size, $family, $color) {
  font-size: calc($size / $primary-font-size-value) + rem;
  font-family: get-font-family-of-weight($weight: $family);
  color: $color;
}

@mixin width ($width) {
  width: $width / $primary-font-size-value + rem;
}

@mixin height ($height) {
  height: $height / $primary-font-size-value + rem;
}

@mixin respond ($breakpoint) {
  $raw-query: map.get(
    $map: $breakpoints,
    $key: $breakpoint
  );

  @if $raw-query {
    $query: if(
      $condition:
      meta.type-of(
        $value: $raw-query
      ) == 'string',
      $if-true:
      string.unquote(
        $string: $raw-query
      ),
      $if-false:
      meta.inspect(
        $value: $raw-query
      )
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`.'
      'Please make sure it is defined in `$breakpoints` map.';
  }
}
