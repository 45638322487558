/* You can add global styles to this file, and also import other style files */
@import './../helpers/variables';
@import '/src/assets/styles/helpers/mixins';

$font-family: 'Roboto', 'Helvetica Neue', sans-serif;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: $font-family;
}

.mat-select-panel {
  margin-top: 32px;
  border-radius: 12px !important;

  .mat-checkbox-checked.mat-accent .mat-checkbox-background,
  .mat-option:hover:not(.mat-option-disabled),
  .mat-option:focus:not(.mat-option-disabled),
  .mat-option.mat-selected,
  .custom-select .mat-selected {
    background-color: rgb(79 3 80) !important;
    color: rgb(255 255 255) !important;
    border-radius: 12px;

    /* stylelint-disable no-descending-specificity,
    selector-max-compound-selectors */
    .mat-option-text {
      .desc-text {
        color: rgb(255 255 255) !important;
      }
    }
  }
}

.backdrop {
  background: rgb(54 54 54 / 90%);
}

.mat-form-field-disabled {
  opacity: 0.5;
}

.mat-select-arrow {
  border: solid rgb(116 31 116) !important;
  border-width: 0 2px 2px 0 !important;
  padding: 3px !important;
  transform: rotate(45deg);
}

.mat-form-field.mat-focused .mat-select-arrow {
  transform: rotate(45deg) !important;
}

.api-snackbar {
  background-color: rgb(220 53 69) !important;
  color: rgb(255 255 255);
}

.my-class .mat-dialog-content {
  height: 55vh;
}

.my-class .mat-dialog-actions {
  margin-bottom: 0 !important;
}

.table-scroll {
  &::-webkit-scrollbar {
    height: 7px;
    border-radius: 12px;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    height: 7px;
    background: rgba(255 217 255);
    border-radius: 12px;
  }
}

.disable {
  pointer-events: none;
  opacity: 0.5;
}

.mat-form-field-outline-start {
  border-radius: 8px 0 0 8px !important;
  min-width: 37px !important;
}

.mat-form-field-outline {
  background: rgb(57 0 173 / 5%) !important;
  border-radius: 8px;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgb(57 0 173 / 5%) !important;
}

.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: rgb(244 67 54) !important;
}

.mat-form-field-outline-end {
  border-radius: 0 8px 8px 0 !important;
}

.mat-form-field-infix {
  border-top: 5px solid rgb(0 0 0 / 0%) !important;
  display: flex;
}

.mat-select-placeholder,
input::placeholder {
  color: rgb(28 28 28 / 50%) !important;
}

.mat-select-value {
  color: rgb(28 28 28) !important;
}

// Custom Calendar CSS
.mat-datepicker-toggle-active {
  color: rgb(79 3 80);
}

.mat-datepicker-content .time-container::before {
  content: none !important;
}

.mat-calendar-body-selected {
  background-color: rgb(79 3 80);
}

.mat-tab-label-content {
  text-decoration: none !important;
  border-bottom: none !important;
}

.mat-tab-label {
  margin-bottom: -5px;
}

.mat-tab-header {
  margin-bottom: 2rem;
  background: rgb(252 252 252);
  border: 1px solid rgb(224 224 224);
  border-radius: 16px 16px 0 0;
}

.mat-tab-label-active {
  color: rgb(57 0 173) !important;
}

.custom-dialog {
  .mat-dialog-container {
    border-radius: 16px;
  }
}

.ellipses {
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-tooltip {
  max-width: none !important;
  overflow: auto !important;
  text-overflow: unset !important;
}

.copy-icon {
  margin-left: 10px;
  cursor: pointer;
}

.add-btn {
  @include font($size: 12, $family:JioTypeMedium,
  $color: rgb(255 255 255));

  background: rgb(79 3 80);
  border-radius: 8px !important;
  padding: 0 20px !important;
  height: 48px;
  text-transform: uppercase;

  .mat-button-wrapper {
    @include flex-container(
      $justify: center,
      $align: center,
      $flex-direction: row);

    .my-icon {
      @include flex-container(
        $justify: center,
        $align: flex-end,
        $flex-direction: row);

      margin-left: 8px;
      font-size: 15px;
      width: 15px;
    }
  }
}

.section-header {
  @include font(
    $size: 16,
    $family: JioTypeBlack !important,
    $color: #141414 !important
  );

  margin-bottom: 1rem;
}

.d-inline {
  display: inline;
}

.custom-filter-button {
  @include flex-container($align: center,
  $justify: space-between,
  $flex-direction: row);

  padding: 0 12px;
  margin-bottom: 8px;

  .txt-blue {
    color: rgb(57 0 173);
    font-weight: 500;
    text-transform: uppercase;
  }

  .clear-btn {
    height: 35px !important;
    line-height: 33px;
  }
}
