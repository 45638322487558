@import './../helpers/variables';

* {
  box-sizing: border-box;
  outline: none;
  -webkit-overflow-scrolling: touch;
}

html {
  font-size: $primary-font-size;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
}

body {
  font-family: $primary-font-family;
  color: $primary-color;
  margin: 0;
  padding: 0;
  background: $background-color1;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.scroll-lock {
    overflow: hidden !important;
  }
}

input {
  user-select: initial !important;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.clearfix {
  clear: both;
}

.full {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

/* Iphone 2G/3G/4/4S */
@media only screen and (min-device-width: $iphone-min-width) and
  (max-device-width: $iphone-max-width) {
  // responsive for iphone 2G/3G/4/4s
}

/* Iphone 5 */
@media only screen and (min-device-width: $iphone-min-width) and
  (max-device-width: $iphone-five-max-width) {
  html {
    font-size: 12px !important;
  }
}

/* Iphone 6/7/8 */
@media only screen and (min-device-width: $iphone-six-to-eight-min-width) and
  (max-device-width: $iphone-six-to-eight-max-width) {
  html {
    font-size: 14px !important;
  }
}

/* Iphone 6/7/8 Plus */
@media only screen and (min-device-width: $iphone-six-to-eight-plus-min-width)
  and (max-device-width: $iphone-six-to-eight-plus-max-width) {
  html {
    font-size: 15px !important;
  }
}

/* Iphone X */
@media only screen and (min-width: $iphone-x-min-width) and
  (max-width: $iphone-x-max-width) {
  // (device-pixel-ratio: 3) {
  // responsive for iphone x
}

/* For tablets: */
@media only screen and (min-width: $min-width) {
  .col-s-1 {
    width: 8.33%;
  }

  .col-s-2 {
    width: 16.66%;
  }

  .col-s-3 {
    width: 25%;
  }

  .col-s-4 {
    width: 33.33%;
  }

  .col-s-5 {
    width: 41.66%;
  }

  .col-s-6 {
    width: 50%;
  }

  .col-s-7 {
    width: 58.33%;
  }

  .col-s-8 {
    width: 66.66%;
  }

  .col-s-9 {
    width: 75%;
  }

  .col-s-10 {
    width: 83.33%;
  }

  .col-s-11 {
    width: 91.66%;
  }

  .col-s-12 {
    width: 100%;
  }
}

/* For Small Screen: */
@media only screen and (min-width: $max-width) {
  .col-1 {
    width: 8.33%;
  }

  .col-2 {
    width: 16.66%;
  }

  .col-3 {
    width: 25%;
  }

  .col-4 {
    width: 33.33%;
  }

  .col-5 {
    width: 41.66%;
  }

  .col-6 {
    width: 50%;
  }

  .col-7 {
    width: 58.33%;
  }

  .col-8 {
    width: 66.66%;
  }

  .col-9 {
    width: 75%;
  }

  .col-10 {
    width: 83.33%;
  }

  .col-11 {
    width: 91.66%;
  }

  .col-12 {
    width: 100%;
  }
}

/* For Medium Screen: */
@media only screen and (min-width: $min-width1) and (max-width: $min-width2) {
  .col-md-1 {
    width: 8.33%;
  }

  .col-md-2 {
    width: 16.66%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-4 {
    width: 33.33%;
  }

  .col-md-5 {
    width: 41.66%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-7 {
    width: 58.33%;
  }

  .col-md-8 {
    width: 66.66%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-10 {
    width: 83.33%;
  }

  .col-md-11 {
    width: 91.66%;
  }

  .col-md-12 {
    width: 100%;
  }
}

/* For Large Screen: */
@media only screen and (min-width: $min-width3) {
  .col-lg-1 {
    width: 8.33%;
  }

  .col-lg-2 {
    width: 16.66%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-4 {
    width: 33.33%;
  }

  .col-lg-5 {
    width: 41.66%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-7 {
    width: 58.33%;
  }

  .col-lg-8 {
    width: 66.66%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-10 {
    width: 83.33%;
  }

  .col-lg-11 {
    width: 91.66%;
  }

  .col-lg-12 {
    width: 100%;
  }
}

/* For Extra Large Screen: */
@media only screen and (min-width: $min-width4) {
  .col-xl-1 {
    width: 8.33%;
  }

  .col-xl-2 {
    width: 16.66%;
  }

  .col-xl-3 {
    width: 25%;
  }

  .col-xl-4 {
    width: 33.33%;
  }

  .col-xl-5 {
    width: 41.66%;
  }

  .col-xl-6 {
    width: 50%;
  }

  .col-xl-7 {
    width: 58.33%;
  }

  .col-xl-8 {
    width: 66.66%;
  }

  .col-xl-9 {
    width: 75%;
  }

  .col-xl-10 {
    width: 83.33%;
  }

  .col-xl-11 {
    width: 91.66%;
  }

  .col-xl-12 {
    width: 100%;
  }
}

/* For Extra Extra Large Screen: */
@media only screen and (min-width: $min-width5) {
  .col-xxl-1 {
    width: 8.33%;
  }

  .col-xxl-2 {
    width: 16.66%;
  }

  .col-xxl-3 {
    width: 25%;
  }

  .col-xxl-4 {
    width: 33.33%;
  }

  .col-xxl-5 {
    width: 41.66%;
  }

  .col-xxl-6 {
    width: 50%;
  }

  .col-xxl-7 {
    width: 58.33%;
  }

  .col-xxl-8 {
    width: 66.66%;
  }

  .col-xxl-9 {
    width: 75%;
  }

  .col-xxl-10 {
    width: 83.33%;
  }

  .col-xxl-11 {
    width: 91.66%;
  }

  .col-xxl-12 {
    width: 100%;
  }
}

@media only screen and (max-width: $min-width6) {
  .count-txt {
    font-size: 18px !important;
    margin-bottom: 0 !important;
  }

  .content-txt {
    margin: 0 !important;
    font-size: 10px !important;
    width: auto !important;
    line-height: 14px;
  }

  .pending {
    @include font($size: 10, $family: JioTypeBold, $color: rgb(250 125 25));
  }

  .live {
    @include font($size: 10, $family: JioTypeBold, $color: rgb(0 178 89));
  }

  .expire {
    @include font($size: 10, $family: JioTypeBold, $color: rgb(227 5 19));
  }

  .upcoming {
    @include font($size: 10, $family: JioTypeBold, $color: rgb(109 23 206));
  }

  .partner {
    @include font($size: 10, $family: JioTypeBold, $color:rgb(217 0 141));
  }

  .onboard {
    @include font($size: 10, $family: JioTypeBold, $color:rgb(42 0 9));
  }

  .status-box {
    padding: 2px 4px !important;
    margin-bottom: 1px !important;
    font-size: 10px !important;

    p {
      font-size: 10px;
    }
  }

  .member-txt {
    font-size: 12px !important;
    line-height: 1;
  }

  .light-card,
  .dark-card {
    padding: 10px 11px !important;

    .card-title {
      font-size: 18px !important;
      margin: 4px !important;
    }

    .enollment-title {
      font-size: 18px !important;
      margin: 4px !important;
    }

    .mat-card-header {
      margin: 0;
    }
  }

  .member-count {
    @include font($size: 21, $family: JioTypeBlack, $color: rgb(255 255 255));

    margin-bottom: 0;
  }

  .main-wrapper {
    height: calc(100vh - 140px);
    overflow-y: auto;
  }
}

.row {
  display: flex;
}
