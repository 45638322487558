@import 'helpers/fonts';
@import 'helpers/variables';
@import 'src/assets/styles/helpers/mixins';
@import 'helpers/animations';
@import 'src/assets/styles/layout/structure';
@import 'src/assets/styles/layout/generic';
@import 'helpers/commons';
@import 'helpers/buttons';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
