@import 'mixins';

.merchant-btn {
  @include flex-container($align: center, $justify: center);
  @include font($size: 18, $family: HelveticaNeuemedium, $color: #ffffff);

  background:
    linear-gradient(106deg, rgb(116 31 116) 0%, rgb(77 9 78) 100%) 0%
    0%;
  width: 147px;
  height: 48px;
  border-radius: 12px !important;
  margin: 0 auto !important;
}
