@import 'variables';

@function get-font-family-of-weight ($weight) {
  @return $weight;
}

@font-face {
  font-family: get-font-family-of-weight($weight: 'HelveticaNeue');
  src: url('~src/assets/fonts/helvetica/HelveticaNeue.ttf') format('truetype');
}

@font-face {
  font-family: get-font-family-of-weight($weight: 'HelveticaNeuebold');
  src:
    url('~src/assets/fonts/helvetica/HelveticaNeue-Bold.ttf')
    format('truetype');
}

@font-face {
  font-family: get-font-family-of-weight($weight: 'HelveticaNeuelight');
  src:
    url('~src/assets/fonts/helvetica/HelveticaNeue-Light.ttf')
    format('truetype');
}

@font-face {
  font-family: get-font-family-of-weight($weight: 'HelveticaNeuemedium');
  src:
    url('~src/assets/fonts/helvetica/HelveticaNeue-Medium.ttf')
    format('truetype');
}

@font-face {
  font-family: get-font-family-of-weight($weight: 'HelveticaNeueregular');
  src:
    url('~src/assets/fonts/helvetica/HelveticaNeue-Regular.ttf')
    format('truetype');
}

@font-face {
  font-family: get-font-family-of-weight($weight: 'JioTypeBlack');
  src:
    url('~src/assets/fonts/jio/JioType-Black.ttf')
    format('truetype');
}

@font-face {
  font-family: get-font-family-of-weight($weight: 'JioTypeMedium');
  src:
    url('~src/assets/fonts/jio/JioType-Medium.ttf')
    format('truetype');
}

@font-face {
  font-family: get-font-family-of-weight($weight: 'JioTypeBold');
  src:
    url('~src/assets/fonts/jio/JioType-Bold.ttf')
    format('truetype');
}

@font-face {
  font-family: get-font-family-of-weight($weight: 'JioTypeLight');
  src:
    url('~src/assets/fonts/jio/JioType-Light.ttf')
    format('truetype');
}
