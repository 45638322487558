.scale-up-center {
  animation: scale-up-center 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.slide-top {
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.shadow-inset-center {
  animation: shadow-inset-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-top {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, -100px, 0);
  }
}

@keyframes scale-up-center {
  0% {
    transform: scale(0.75);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes shadow-inset-center {
  0% {
    box-shadow: inset 0 0 0 0 rgb(0 0 0 / 0%);
  }

  100% {
    box-shadow: inset 0 0 14px 0 rgb(0 0 0 / 50%);
  }
}

@keyframes slide-up {
  0% {
    transform: translate3d(0, 2rem, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-down {
  0% {
    transform: translate3d(0, -2rem, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-left {
  0% {
    transform: translate3d(100%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-right-in {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-right-out {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slide-right {
  0% {
    left: 0;
  }

  100% {
    left: 100%;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes scale-up {
  from {
    transform: scale(0.5);
  }

  to {
    transform: scale(1);
  }
}

@keyframes shine {
  10% {
    opacity: 1;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }

  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}

@keyframes spinning {
  from {
    transform: translateZ(-5em) rotateY(0deg);
  }

  to {
    transform: translateZ(-5em) rotateY(360deg);
  }
}
